import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps, GetStaticPropsContext } from 'next';

import client from 'client/apollo-client';
import getHrefs from 'helpers/getHrefs';
import Body from 'components/Layout/Body';
import Container from 'components/Layout/Container';
import Footer from 'components/Footer/Footer';
import TopNav from 'components/Navigation/TopNav';
import { HomeDefaultsQuery, HomePostsQuery, LoadMoreHomeQuery } from 'queries';
import findCurrentTranslation from 'utilities/findCurrentTranslation';
import { useTrackEventViewedPage } from 'hooks/useTrackEventViewedPage';
import { useTrackEventViewedBlogPage } from 'hooks/useTrackEventViewedBlogPage';
import { generateHomePageSchema } from 'utilities/generageHomePageSchema';
import PageSEO from 'components/SEO/PageSEO';
import returnNotFound from 'libs/returnNotFound';
import PostGrid from 'components/Grids/PostGrid';
import PostCardHero from 'components/Cards/PostCardHero';

export const INDEX_POSTS_PER_PAGE = 9;

export const TRANS_ARRAY: Blog.TranslationType[] = [
  {
    code: 'en',
    pathname: '/',
  },
  {
    code: 'es',
    pathname: '/',
  },
  {
    code: 'fr',
    pathname: '/',
  },
  {
    code: 'de',
    pathname: '/',
  },
  {
    code: 'pt',
    pathname: '/',
  },
];

const Index: React.FC<Blog.IndexInterface> = ({
  locale,
  postData,
  translations,
}) => {
  useTrackEventViewedPage({ page_name: 'home' });
  useTrackEventViewedBlogPage();

  const { t } = useTranslation('common');

  const title = t('title');
  const description = t('desc');
  const hrefs = getHrefs(translations);
  const schema = generateHomePageSchema(t, locale);

  return (
    <>
      <PageSEO
        canonicalUrl="/"
        description={description}
        hrefs={hrefs}
        locale={locale}
        schema={schema}
        title={title}
      />
      <TopNav translations={translations} locale={locale} />
      <Body>
        <Container>
          <PostCardHero post={postData.HeroPost?.nodes?.[0]} locale={locale} />
          <PostGrid
            hasNextPage={postData.GridPosts.pageInfo?.hasNextPage}
            posts={postData.GridPosts.nodes}
            newsletter={mapNewsletterFields(postData.Newsletter)}
            endCursor={postData.GridPosts.pageInfo?.endCursor}
            locale={locale}
            query={LoadMoreHomeQuery}
            queryCount={INDEX_POSTS_PER_PAGE}
            queryArgs={{ notIn: postData.HeroPost.nodes?.[0]?.id }}
            startCursor={postData.GridPosts.pageInfo?.startCursor}
          />
        </Container>
        <Footer translations={translations} locale={locale} />
      </Body>
    </>
  );
};

export const getStaticProps: GetStaticProps = async (
  context: GetStaticPropsContext
) => {
  const locale =
    (context?.locale as Blog.LocaleType) ?? ('en' as Blog.LocaleType);

  const { data: homeDefaultsData, errors: homeDefaultsErrors } =
    await client.query({
      query: HomeDefaultsQuery,
      variables: {
        slug: `home${locale !== 'en' && locale !== 'default' ? '-' + locale : ''}`,
      },
    });

  if (homeDefaultsErrors) {
    return returnNotFound(locale, locale + '/');
  }

  const featuredId =
    homeDefaultsData.indexPages.nodes[0]?.indexPageFields.indexPageFieldGroup
      .featuredPost?.databaseId ?? '';
  const newsletterId =
    homeDefaultsData.indexPages.nodes[0]?.indexPageFields.indexPageFieldGroup
      .featuredNewsletter?.id ?? '';

  const { data: homeData, errors: homeErrors } = await client.query({
    query: HomePostsQuery,
    variables: {
      id: featuredId,
      first: INDEX_POSTS_PER_PAGE,
      locale: locale,
      notIn: featuredId,
      newsletterId: newsletterId,
    },
  });

  if (homeErrors) {
    return returnNotFound(locale, locale + '/');
  }

  const translations = findCurrentTranslation(TRANS_ARRAY, locale);

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      locale: locale,
      postData: homeData,
      translations: translations,
    },
  };
};

export default Index;

export const mapNewsletterFields = (data: {
  newsletterSignupFields: {
    newsletterSignupFieldGroup: {
      heading: string;
      text: string;
      buttonText: string;
      redirectUrl: string;
    };
  };
}): Omit<Blog.NewsletterFormProps, 'color_theme'> => {
  return {
    heading:
      data.newsletterSignupFields.newsletterSignupFieldGroup?.heading ?? '',
    text: data.newsletterSignupFields.newsletterSignupFieldGroup?.text ?? '',
    button_text:
      data.newsletterSignupFields.newsletterSignupFieldGroup?.buttonText ?? '',
    redirect_url:
      data.newsletterSignupFields.newsletterSignupFieldGroup?.redirectUrl ??
      null,
  };
};
