import { TFunction } from 'i18next/index.js';

export const generateHomePageSchema = (
  t: TFunction,
  locale: Blog.LocaleType = 'en'
) => {
  if (!t) return '';
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'CollectionPage',
        '@id': `https://www.skillshare.com${locale && '/' + locale}/blog/`,
        url: `https://www.skillshare.com${locale && '/' + locale}/blog/`,
        name: t('title'),
        isPartOf: { '@id': 'https://www.skillshare.com/blog/#website' },
        about: { '@id': 'https://www.skillshare.com/blog/#organization' },
        description: t('desc'),
        breadcrumb: { '@id': 'https://www.skillshare.com/blog/#breadcrumb' },
        inLanguage: 'en-US',
      },
      {
        '@type': 'BreadcrumbList',
        '@id': 'https://www.skillshare.com/blog/#breadcrumb',
        itemListElement: [{ '@type': 'ListItem', position: 1, name: 'Home' }],
      },
      {
        '@type': 'WebSite',
        '@id': 'https://www.skillshare.com/blog/#website',
        url: 'https://www.skillshare.com/blog/',
        name: 'Skillshare Blog',
        description: '',
        publisher: { '@id': 'https://www.skillshare.com/blog/#organization' },
        inLanguage: 'en-US',
      },
      {
        '@type': 'Organization',
        '@id': 'https://www.skillshare.com/blog/#organization',
        name: 'Skillshare, Inc.',
        url: 'https://www.skillshare.com/blog/',
        sameAs: [],
        logo: {
          '@type': 'ImageObject',
          inLanguage: 'en-US',
          '@id': 'https://www.skillshare.com/blog/#/schema/logo/image/',
          url: 'https://www.skillshare.com/blog/wp-content/uploads/2021/03/Skillshare_blog.png',
          contentUrl:
            'https://www.skillshare.com/blog/wp-content/uploads/2021/03/Skillshare_blog.png',
          width: 276,
          height: 100,
          caption: 'Skillshare, Inc.',
        },
        image: {
          '@id': 'https://www.skillshare.com/blog/#/schema/logo/image/',
        },
      },
    ],
  });
};
