import { gql } from '@apollo/client';

export const DefaultStickyCtaQuery = gql`
  fragment StickyCTAFields on StickyCta {
    id
    stickyCtaFields {
      stickyCtaFieldGroup {
        button {
          target
          title
          url
        }
        headline
        values {
          value
        }
      }
    }
  }

  query DefaultStickyCtaQuery(
    $en: Boolean = false
    $es: Boolean = false
    $de: Boolean = false
    $fr: Boolean = false
    $pt: Boolean = false
  ) {
    acfOptions {
      acfOptionsFields {
        defaultStickyCtas {
          defaultEnglishStickyCta @include(if: $en) {
            ... on StickyCta {
              ...StickyCTAFields
            }
          }
          defaultSpanishStickyCta @include(if: $es) {
            ... on StickyCta {
              ...StickyCTAFields
            }
          }
          defaultGermanStickyCta @include(if: $de) {
            ... on StickyCta {
              ...StickyCTAFields
            }
          }
          defaultFrenchStickyCta @include(if: $fr) {
            ... on StickyCta {
              ...StickyCTAFields
            }
          }
          defaultPortugueseStickyCta @include(if: $pt) {
            ... on StickyCta {
              ...StickyCTAFields
            }
          }
        }
      }
    }
  }
`;
