import { gql } from '@apollo/client';

export const HomeDefaultsQuery = gql`
  query HomeDefaultsQuery($slug: String) {
    indexPages(where: { name: $slug }) {
      nodes {
        indexPageFields {
          indexPageFieldGroup {
            featuredNewsletter {
              ... on NewsletterSignup {
                id
              }
            }
            featuredPost {
              ... on Post {
                databaseId
                contentTypeName
              }
              ... on Page {
                databaseId
                contentTypeName
              }
            }
          }
        }
      }
    }
  }
`;

export const HomePostsQuery = gql`
  query HomePostsQuery(
    $id: Int
    $locale: String
    $notIn: [ID]
    $first: Int
    $newsletterId: ID!
  ) {
    HeroPost: posts(first: 1, where: { id: $id, postTypes: ["post", "page"] }) {
      nodes {
        authorship {
          displayName
          type
          profilePic
        }
        contentAssetType
        locale {
          locale
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        excerpt(format: RENDERED)
        date
        modified
        slug
        title
      }
    }
    GridPosts: posts(
      first: $first
      where: {
        wpmlLanguage: $locale
        notIn: $notIn
        hasPassword: false
        postTypes: ["post", "page"]
        status: PUBLISH
      }
    ) {
      nodes {
        locale {
          locale
        }
        authorship {
          displayName
          type
          profilePic
        }
        contentAssetType
        featuredImage {
          node {
            sourceUrl
          }
        }
        excerpt(format: RENDERED)
        date
        modified
        slug
        title
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
    Newsletter: newsletterSignup(id: $newsletterId) {
      newsletterSignupFields {
        newsletterSignupFieldGroup {
          buttonText
          heading
          redirectUrl
          text
        }
      }
    }
  }
`;

export const LoadMoreHomeQuery = gql`
  query LoadMoreHomeQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $locale: String
    $notIn: [ID]
  ) {
    posts(
      first: $first
      last: $last
      after: $after
      before: $before
      where: {
        wpmlLanguage: $locale
        notIn: $notIn
        hasPassword: false
        postTypes: ["post", "page"]
        status: PUBLISH
      }
    ) {
      nodes {
        locale {
          locale
        }
        authorship {
          displayName
          type
          profilePic
        }
        contentAssetType
        featuredImage {
          node {
            sourceUrl
          }
        }
        excerpt(format: RENDERED)
        date
        modified
        slug
        title
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
