import Head from 'next/head';
import { sanitize } from 'isomorphic-dompurify';

export const locales = {
  en: 'en_US',
  es: 'es_LA',
  fr: 'fr_FR',
  de: 'de_DE',
  pt: 'pt_BR',
};

interface PageSEOProps {
  description: string;
  hrefs: JSX.Element[];
  locale: Blog.LocaleType;
  schema: string;
  title: string;
  canonicalUrl?: string | null;
}

const PageSEO: React.FC<PageSEOProps> = ({
  description,
  hrefs,
  locale,
  schema,
  title,
  canonicalUrl = null,
}) => {
  return (
    <Head>
      {hrefs.map((cur, index) => {
        return { ...cur, key: index };
      })}
      <title>{title}</title>
      <meta key="0" name="description" content={description} />
      <meta
        key="1"
        property="og:locale"
        content={locale in locales ? locales[locale] : locales.en}
      />
      <meta key="2" property="og:type" content="website" />
      <meta
        key="3"
        property="og:url"
        content="https://www.skillshare.com/blog/"
      />
      <meta key="4" property="og:site_name" content="Skillshare Blog" />
      <meta key="5" name="twitter:card" content="summary_large_image" />
      <script
        key="6"
        type="application/ld+json"
        className="yoast-schema-graph"
        dangerouslySetInnerHTML={{ __html: sanitize(schema) }}
      />
      {canonicalUrl && canonicalUrl === '/' ? (
        <link
          rel="canonical"
          href={`https://www.skillshare.com${locale && '/' + locale}/blog/`}
        />
      ) : (
        <link
          rel="canonical"
          href={`https://www.skillshare.com/${locale}/blog/${canonicalUrl + '/'}`}
        />
      )}
    </Head>
  );
};

export default PageSEO;
