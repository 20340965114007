import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { DocumentNode } from 'graphql';
import { useTranslation } from 'next-i18next';
import * as Sentry from '@sentry/browser';

import client from 'client/apollo-client';
import { Post } from 'gql/graphql';
import PostCard from 'components/Cards/PostCard';
import NewsletterSignupBlock from 'components/Blocks/NewsletterSignupBlock';
import Button from 'components/Buttons/Button';

import { NoResults } from '../Filters/NoResults';

export interface PostGridProps {
  endCursor: string;
  hasNextPage: boolean;
  posts: Post[];
  locale: Blog.LocaleType;
  maxPages?: number;
  newsletter: object;
  query: DocumentNode;
  filterQuery?: DocumentNode;
  queryCount: number;
  queryArgs?: { [key: string]: number | string | boolean | Array<string> };
  filterArgs?: Blog.FilterArgs;
  startCursor: string;
  showPostPill?: boolean;
}

type PostOrSkeleton = Post | 'skeleton';

const PostGrid: React.FC<PostGridProps> = ({
  endCursor: propEndCursor,
  hasNextPage: hasNextPageProp,
  locale,
  maxPages = 3,
  newsletter,
  posts,
  query,
  queryCount = 9,
  queryArgs,
  filterQuery = null,
  filterArgs = { contentAssetType: [], contentTopics: [] },
  startCursor: propStartCursor,
  showPostPill = true,
}) => {
  const [startCursor, setStartCursor] = useState<string>();
  const [endCursor, setEndCursor] = useState<string>();

  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);

  const [error, setError] = useState(false);

  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loadedPosts, setLoadedPosts] = useState<PostOrSkeleton[]>([]);

  const { t } = useTranslation('common');

  const scrollRef = useRef<HTMLDivElement>();
  const [scrollDirection, setScrollDirection] = useState<'next' | 'prev'>();

  const MAX_POSTS = queryCount * maxPages;

  useEffect(() => {
    if (!posts.length) {
      Sentry.captureMessage('No posts returned from query.', {
        level: 'error',
        tags: {
          component: 'PostGrid',
          locale: locale,
        },
      });
      setHasNextPage(false);
      setHasPrevPage(false);
      setError(true);
      return;
    }

    if (typeof window !== 'undefined') {
      const url = new URLSearchParams(window.location.search);
      if (url.has('topic') || url.has('content')) {
        setLoadedPosts([...makeSkeletonPosts(1)]);
        return;
      }
    }

    setLoadedPosts(posts);
  }, [posts, setError, locale]);

  useEffect(() => {
    if (!endCursor && propEndCursor) {
      setEndCursor(propEndCursor);
    }
    if (!startCursor && propStartCursor) {
      setStartCursor(propStartCursor);
    }
  }, [propEndCursor, endCursor, startCursor, propStartCursor]);

  useEffect(() => {
    setHasNextPage(hasNextPageProp);
  }, [hasNextPageProp]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [loadedPosts]);

  const loadPosts = async ({ isForward }) => {
    setScrollDirection(isForward ? 'next' : 'prev');
    const setLoading = isForward ? setLoadingMore : setLoadingPrev;
    if (isForward) {
      const currentPosts = [...loadedPosts, ...makeSkeletonPosts(queryCount)];
      if (currentPosts.length > MAX_POSTS) {
        setLoadedPosts(currentPosts.slice(-queryCount));
      } else {
        setLoadedPosts(currentPosts.slice(-MAX_POSTS));
      }
    }

    setLoading(true);

    const variables = {
      first: isForward ? queryCount : null,
      last: isForward ? null : queryCount,
      after: isForward ? endCursor : null,
      before: isForward ? null : startCursor,
      locale: locale,
      contentTopics: filterArgs.contentTopics || [],
      contentAssetType:
        filterArgs.contentAssetType || queryArgs.contentAssetType,
      ...queryArgs,
    };

    try {
      const { data, errors } = await client.query({
        query,
        variables,
      });

      if (errors) {
        Sentry.captureMessage(
          `Error in loading ${isForward ? 'more' : 'previous'} posts. ${errors.toString()}`,
          {
            level: 'error',
            tags: { component: 'PostGrid', locale: locale },
          }
        );
        setLoading(false);
        setHasNextPage(false);
        setHasPrevPage(false);
        setError(true);
        return;
      }

      const key = data.posts ? 'posts' : 'pages';
      const newPosts = data[key].nodes;
      const pageInfo = data[key].pageInfo;

      setStartCursor(pageInfo.startCursor);
      setEndCursor(pageInfo.endCursor);
      setHasNextPage(pageInfo.hasNextPage);
      setLoading(false);

      if (isForward) {
        const allPosts = [...loadedPosts, ...newPosts];
        if (allPosts.length > MAX_POSTS) {
          setLoadedPosts(allPosts.slice(-queryCount));
          setHasPrevPage(pageInfo.hasPreviousPage);
        } else {
          setLoadedPosts(allPosts.slice(-MAX_POSTS));
        }
      } else {
        setLoadedPosts([...newPosts, ...loadedPosts].slice(0, MAX_POSTS));
        setHasPrevPage(pageInfo.hasPreviousPage);
      }
    } catch (err) {
      Sentry.captureMessage(
        `Failed to load ${isForward ? 'more' : 'previous'} posts. ${err.message}`,
        {
          level: 'error',
          tags: { component: 'PostGrid', locale: locale },
        }
      );
      setLoading(false);
      setHasPrevPage(false);
      setHasNextPage(false);
      setError(true);
    }
  };

  useEffect(() => {
    const filterPosts = async () => {
      if (!filterQuery) {
        return;
      }

      const currentPosts = [...makeSkeletonPosts(queryCount)];

      setLoadingMore(true);
      setLoadedPosts(currentPosts);

      const variables = {
        first: queryCount,
        last: null,
        after: null,
        before: null,
        locale: locale,
        newsletterId: 0,
        skipNewsletter: true,
        contentTopics: filterArgs.contentTopics || [],
        contentAssetType:
          filterArgs.contentAssetType || queryArgs.contentAssetType,
      };

      try {
        const { data, errors } = await client.query({
          query: filterQuery,
          variables,
        });

        if (errors) {
          Sentry.captureMessage(
            `Error in filtering posts. ${errors.toString()}`,
            {
              level: 'error',
              tags: { component: 'PostGrid', locale: locale },
            }
          );

          setLoadingMore(false);
          setHasNextPage(false);
          setHasPrevPage(false);
          setError(true);
          setLoadedPosts([]);
          return;
        }

        const newPosts = data.GridPosts.nodes;
        const pageInfo = data.GridPosts.pageInfo;

        setStartCursor(pageInfo.startCursor);
        setEndCursor(pageInfo.endCursor);
        setHasNextPage(pageInfo.hasNextPage);
        setLoadingMore(false);

        const allPosts = [...newPosts];
        if (allPosts.length > MAX_POSTS) {
          setLoadedPosts(allPosts.slice(-queryCount));
          setHasPrevPage(pageInfo.hasPreviousPage);
        } else {
          setLoadedPosts(allPosts.slice(-MAX_POSTS));
        }
      } catch (err) {
        Sentry.captureMessage(`Failed to filter posts. ${err.message}`, {
          level: 'error',
          tags: { component: 'PostGrid', locale: locale },
        });
        setLoadingMore(false);
        setHasPrevPage(false);
        setHasNextPage(false);
        setLoadedPosts([]);
        setError(true);
      }
    };

    filterPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterArgs.contentAssetType, filterArgs.contentTopics]);

  const hasPosts = loadedPosts.length > 0;

  return (
    <>
      {error && <LoadingError />}
      {hasPrevPage && (
        <div data-testid="load-prev-btn" className="my-16 flex justify-center">
          <Button
            theme="Transparent"
            onClick={() => {
              return loadPosts({ isForward: false });
            }}
            style={`col-span-12 row-start-3 ${loadingPrev ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {t('loadPrev')}
          </Button>
        </div>
      )}
      {!error && !hasPosts && <NoResults />}
      <div
        data-testid="post-grid"
        className={`grid grid-cols-12 ${hasPosts ? 'mt-[32px] gap-12' : ''}`}
      >
        {locale === 'en' && (
          <div className="col-span-12 row-start-4 md:row-start-2 lg:row-start-2">
            <NewsletterSignupBlock
              locale={locale}
              color_theme="Green"
              {...(newsletter as Blog.NewsletterFormProps)}
            />
          </div>
        )}

        {loadedPosts.map((post, ind, arr) => {
          let maybeRef;
          if (scrollDirection === 'next') {
            maybeRef =
              ind === arr.length - queryCount ? { innerRef: scrollRef } : {};
          } else if (scrollDirection === 'prev') {
            maybeRef = ind === 0 ? { innerRef: scrollRef } : {};
          }

          if (post === 'skeleton') {
            return <PostCardSkeleton {...maybeRef} key={'skeleton-' + ind} />;
          }

          return (
            <PostCard
              key={'post-' + ind}
              post={post}
              locale={locale}
              showPill={showPostPill}
              {...maybeRef}
            />
          );
        })}
      </div>
      {hasNextPage && (
        <div data-testid="load-more-btn" className="my-16 flex justify-center">
          <Button
            theme="Transparent"
            onClick={() => {
              return loadPosts({ isForward: true });
            }}
            style={`col-span-12 row-start-3 ${loadingMore ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {t('loadMore')}
          </Button>
        </div>
      )}
    </>
  );
};

export default PostGrid;

const PostCardSkeleton: React.FC = (props: {
  innerRef?: MutableRefObject<HTMLDivElement>;
}) => {
  const { innerRef } = props;
  return (
    <div
      ref={innerRef}
      data-testid="post-card-skeleton"
      className="bg-[#DCDEE1] animate-pulse col-span-12 md:col-span-6 lg:col-span-4 row-span-1 h-[331px] rounded-[8px]"
    ></div>
  );
};

const LoadingError: React.FC = () => {
  return (
    <div
      data-testid="post-grid-error"
      className="col-span-12 row-span-1 h-[331px] bg-white rounded-[8px] flex items-center justify-center mb-[24px]"
    >
      <h2>
        We&apos;re sorry, an error has occurred. Please reload and try again.
      </h2>
    </div>
  );
};

const makeSkeletonPosts = (count = 9): 'skeleton'[] => {
  return Array.from({ length: count }, () => 'skeleton');
};
