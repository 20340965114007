import { gql } from '@apollo/client';

import { blocks } from '../wp-blocks';

export const SinglePostQuery = gql`
	${blocks.CoreParagraph.fragments.entry}
	${blocks.CoreColumns.fragments.entry}
	${blocks.CoreColumn.fragments.entry}
	${blocks.CoreCode.fragments.entry}
	${blocks.CoreButtons.fragments.entry}
	${blocks.CoreButton.fragments.entry}
	${blocks.CoreQuote.fragments.entry}
	${blocks.ImageProxy.fragments.entry}
	${blocks.CoreSeparator.fragments.entry}
	${blocks.CoreList.fragments.entry}
	${blocks.CoreHeading.fragments.entry}
	${blocks.CoreGroupBlock.fragments.entry}
	${blocks.MaxWidthBlock.fragments.entry}
	${blocks.CoreEmbedBlockProxy.fragments.entry}
	query SinglePostQuery($slug: String) {
		posts(first: 1, where: { name: $slug }) {
			nodes {
				databaseId
				author {
					node {
						description
						name
					}
				}
				authorship {
					bio
					displayName
					type
					profilePic
					teacherUrl
					teacherType
				}
				locale {
					locale
				}
				featuredImage {
					node {
						sourceUrl
					}
				}
				excerpt(format: RENDERED)
				date
				modified
				slug
				title
				contentAssetType
				seo {
					fullHead
					schema {
						raw
					}
					readingTime
					opengraphUrl
				}
				stickyCta {
					stickyCtaSelect {
						... on StickyCta {
							stickyCtaFields {
								stickyCtaFieldGroup {
									button {
										target
										title
										url
									}
									headline
									values {
										value
									}
								}
							}
						}
					}
				}
				translations {
					href
					locale
				}
				editorBlocks(flat: true) {
					__typename
					renderedHtml
					id: clientId
					parentClientId
					...${blocks.CoreParagraph.fragments.key}
					...${blocks.CoreColumns.fragments.key}
					...${blocks.CoreColumn.fragments.key}
					...${blocks.CoreCode.fragments.key}
					...${blocks.CoreButtons.fragments.key}
					...${blocks.CoreButton.fragments.key}
					...${blocks.CoreQuote.fragments.key}
					...${blocks.ImageProxy.fragments.key}
					...${blocks.CoreSeparator.fragments.key}
					...${blocks.CoreList.fragments.key}
					...${blocks.CoreHeading.fragments.key}
					...${blocks.CoreGroupBlock.fragments.key}
					...${blocks.MaxWidthBlock.fragments.key}
					...${blocks.CoreEmbedBlockProxy.fragments.key}
				}
			}
		}
	}
`;

export const SinglePageQuery = gql`
	${blocks.CoreParagraph.fragments.entry}
	${blocks.CoreColumns.fragments.entry}
	${blocks.CoreColumn.fragments.entry}
	${blocks.CoreCode.fragments.entry}
	${blocks.CoreButtons.fragments.entry}
	${blocks.CoreButton.fragments.entry}
	${blocks.CoreQuote.fragments.entry}
	${blocks.ImageProxy.fragments.entry}
	${blocks.CoreSeparator.fragments.entry}
	${blocks.CoreList.fragments.entry}
	${blocks.CoreHeading.fragments.entry}
	${blocks.CoreGroupBlock.fragments.entry}
	${blocks.MaxWidthBlock.fragments.entry}
	${blocks.CoreEmbedBlockProxy.fragments.entry}
	query SinglePageQuery($uri: String) {
		pageBy(uri: $uri) {
			contentAssetType
			gatedContentTracking {
				trackAsGatedContent
			}
			locale {
				locale
			}
			seo {
				fullHead
				schema {
					raw
				}
			}
			translations {
				href
				locale
			}
			editorBlocks(flat: true) {
				__typename
				renderedHtml
				id: clientId
				parentClientId
				...${blocks.CoreParagraph.fragments.key}
				...${blocks.CoreColumns.fragments.key}
				...${blocks.CoreColumn.fragments.key}
				...${blocks.CoreCode.fragments.key}
				...${blocks.CoreButtons.fragments.key}
				...${blocks.CoreButton.fragments.key}
				...${blocks.CoreQuote.fragments.key}
				...${blocks.ImageProxy.fragments.key}
				...${blocks.CoreSeparator.fragments.key}
				...${blocks.CoreList.fragments.key}
				...${blocks.CoreHeading.fragments.key}
				...${blocks.CoreGroupBlock.fragments.key}
				...${blocks.MaxWidthBlock.fragments.key}
				...${blocks.CoreEmbedBlockProxy.fragments.key}
			}
		}
	}
`;

export const TotalPostsQuery = gql`
  query TotalPostsQuery {
    posts(first: 1, where: { hasPassword: false }) {
      nodes {
        id
      }
      pageInfo {
        total
      }
    }
  }
`;

export const AllPostsQuery = gql`
  query AllPostsQuery($after: String) {
    posts(first: 100, after: $after, where: { hasPassword: false }) {
      nodes {
        slug
        locale {
          locale
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
`;

export const TotalPagesQuery = gql`
  query TotalPagesQuery {
    pages(first: 1, where: { hasPassword: false }) {
      nodes {
        id
      }
      pageInfo {
        total
      }
    }
  }
`;

export const AllPagesQuery = gql`
  query AllPagesQuery($after: String) {
    pages(first: 100, after: $after, where: { hasPassword: false }) {
      nodes {
        slug
        locale {
          locale
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
`;
