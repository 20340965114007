import { getFormattedUrl } from '../libs/getFormattedUrl';

const getHrefs = (arr: Blog.TranslationType[]): JSX.Element[] => {
  if (!Array.isArray(arr) || arr.length <= 1) {
    return [];
  }
  return arr.map((cur) => {
    if (cur.pathname === 'blog') {
      return <></>;
    }

    if (cur.pathname !== '/' && cur.pathname.startsWith('/')) {
      cur.pathname = cur.pathname.substring(1);
    }

    return (
      <>
        {cur.code === 'en' ? (
          <link
            data-testid="href-tag"
            key="x-default"
            rel="alternate"
            hrefLang="x-default"
            href={getFormattedUrl(cur.pathname, 'en', false)}
          />
        ) : (
          ''
        )}
        <link
          data-testid="href-tag"
          key={cur.code}
          rel="alternate"
          hrefLang={cur.code}
          href={getFormattedUrl(cur.pathname, cur.code, false)}
        />
      </>
    );
  });
};

export default getHrefs;
