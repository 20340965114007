import { useEffect } from 'react';
import { TrackableEvents } from '@skillshare/ui-components/shared/events';
import { useEventTracker } from '@skillshare/ui-components/shared/hooks/use-event-tracker';

export const useTrackEventViewedPage = ({
  page_name,
  content_type = null,
  post_type = null,
  prefix = 'blog',
  legacy_page = false,
}: {
  page_name: string;
  content_type?: string | null;
  post_type?: string | null;
  prefix?: string;
  legacy_page?: boolean;
}) => {
  const { trackEvent } = useEventTracker();

  useEffect(() => {
    trackEvent(TrackableEvents.ViewedPage, {
      schema: 'search/viewed-page.v1.0.5.schema.json',
      page_name: `${prefix}-${page_name}`,
      ...(content_type ? { content_type } : {}),
      ...(post_type ? { post_type } : {}),
      legacy_page,
    });
    // react-hooks/exhaustive-deps is disabled
    // since code on this useEffect should run only one time when page renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
