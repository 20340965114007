import React from 'react';

export const NoResults = () => {
  return (
    <div className="font-bold text-[23px] leading-[33px] text-navy px-0 pt-[20px] pb-[24px] md:pt-[40px] md:pb-[35px] lg:pt-[50px]">
      Nothing here just yet! We&rsquo;re hard at work preparing fresh content
      for you. Check back soon!
    </div>
  );
};
