import { useEffect } from 'react';
import { TrackableEvents } from '@skillshare/ui-components/shared/events';
import { useEventTracker } from '@skillshare/ui-components/shared/hooks/use-event-tracker';

export const useTrackEventViewedBlogPage = () => {
  const { trackEvent } = useEventTracker();

  useEffect(() => {
    trackEvent(TrackableEvents.ViewedBlogPage, {
      'page-title': document.title,
    });
    // react-hooks/exhaustive-deps is disabled
    // since code on this useEffect should run only one time when page renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
